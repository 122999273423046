@import "src/styles/mixins";

.Product {
  display: flex;
  flex-direction: column;
  //align-items: center;
  //border-top: 1px solid #e7e7e7;
  //padding: 8px 16px;

  background: #fff;
  border-radius: 3px;
  box-shadow:0 1px 5px #00000017;
  //width: 200px;
  //height: 300px;


  padding: 32px;
}

.ImageWrapper {
  $size: 120px;
  width: $size;
  width: 100% !important;
  min-width: $size;
  height: $size;
  min-height: $size;
  //margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }

}

.Info {
  display: flex;
  flex-direction: column;

  @include desktop {
    grid-template-areas: "head left2 left right";
    grid-template-columns: auto 170px 100px 140px;
    align-items: center;
  }
}

.Name {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Stock {
  color: #006400;
  grid-area: left2;
}

.Price {
  margin: 15px 0;
}

.Qty {
  grid-area: right;
  display: flex;
}
