@import "src/styles/mixins";

.CartProduct {
  display: flex;
  align-items: center;
  border-top: 1px solid #e7e7e7;
  padding: 8px 16px;
  padding-right: 0;

}

.ImageWrapper {
  $size: 40px;
  width: $size;
  min-width: $size;
  height: $size;
  min-height: $size;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }

  @include desktop {
    margin-right: 32px;
  }
}

.Info {
  width: 100%;
  display: grid;
  position: relative;
  grid-template-areas:
        "head head"
        "left right"
        "left2 right";
  grid-template-columns: auto 140px;
  @include desktop {
    grid-template-areas: "head left2 left right";
    grid-template-columns: auto 170px 100px 140px;
    align-items: center;
  }
}

.Name {
  grid-area: head;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include desktop {
    margin-right: 32px;
    margin-bottom: 0;
  }
}

.Stock {
  color: #006400;
  grid-area: left2;
  @include desktop {
    margin-left: auto;
    flex-basis: 200px;
    margin-right: 32px;
    width: 100%;
  }
}

.Price {
  grid-area: left;
  @include desktop {
    margin-right: 32px;
    flex-basis: 100px;
  }
}

.Qty {
  grid-area: right;
  display: flex;
}
