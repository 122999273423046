@import "src/styles/mixins";

$sidebar-width: 220px;
$header-height: 60px;

.Layout {
  margin: 0 auto;
  position: relative;

  @include desktop {
    //padding-left: calc(#{$sidebar-width} + 16px);
    //padding-right: 16px;
  }

  @include desktop-wide {
    //background: red;
    max-width: 768px;
  }

}

.Products {

  justify-content: space-between;
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  margin-bottom: 60px;
  //margin-top: 60px;
  @include desktop {
    //margin-top: 0;
    //box-shadow: 0 1px 5px #00000012;
  }
}
