$breakpoints: (
        "phone":        400px,
        "phone-wide":   480px,
        "phablet":      560px,
        "tablet-small": 640px,
        "tablet":       768px,
        "tablet-wide":  1024px,
        "desktop":      1248px,
        "desktop-wide": 1440px
);
@mixin media($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}


$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin desktop-wide {
  @media (min-width: 1280px) {
    @content;
  }
}

h1 {
  font-size: 10px;

  @include tablet {
    font-size: 12px;
  }

  @include desktop {
    font-size: 20px;
  }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
