$primary-color: #282c34;
$secondary-color: #8f95b2;
.color-secondary {
  color: $secondary-color;
}

$margin-xs: 6px;
$margin-sm: 12px;
$margin-md: 18px;
$margin-lg: 24px;
$margin-xl: 30px;