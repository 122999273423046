@import "src/styles/mixins";
@import "../../node_modules/flexboxgrid/css/flexboxgrid.min.css";

body {
  //background-color: #f9f9f9;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  line-height: 20px;
    margin: 0 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  position: relative;
  //overflow: auto;
  //&:before {
  //  content: '';
  //  background-color: #f9f9f9;
  //  z-index: -1;
  //  background-image: url(https://smokelab.org/assets/template/img/topography.svg);
  //  position: fixed;
  //  top: 0;
  //  right: 0;
  //  bottom: 0;
  //  left: 0;
  //}
}


.BoxContent {
  padding: 16px;
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  @include desktop {
    padding: 32px;
  }
}
