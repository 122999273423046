.CitySelector {

}

.LoginForm {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  width: 270px;
  padding: 32px 0;
  margin: 0 auto;

}

.PhoneInput {
  width: 100%;
  border: 1px solid #d8dae5;
  background-color: #fff;
  border-radius: 4px;
  padding: 0 10px;
  outline: 0;
  height: 32px;
  box-sizing: border-box;
  font-size: 12px;
  color: #474d66;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  &[data-isinvalid="true"] {
    border-color: #D14343;
  }
}