@import "src/styles/mixins";
@import "src/styles/variables";

.CartItems {
  border-bottom: 1px solid #e7e7e7;
  @include desktop {
  }
}


.TotalAmount {
  line-height: 32px;
  text-align: right;
}
